<template>
  <div>
    <v-dialog
      v-model="dialog"
      :max-width="paymentMethods[payment?.paymentMethod]?.maxWidth || '470px'"
      :persistent="!error"
      :overlay-opacity="0.5"
      content-class="rounded-lg"
    >
      <v-card :loading="loading" rounded="lg">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <div v-if="error" class="pa-4">
          <v-alert type="error" class="mb-0">{{ error }}</v-alert>
        </div>
        <component
          v-if="payment && !error"
          :is="paymentMethods[payment?.paymentMethod].component"
          :integrationData="integrationData"
          :paymentId="paymentId"
          :payment="payment"
          :tickets="tickets"
          :party="party"
          :bankRestriction="bankRestriction"
          @update="getPayment"
          @refresh="$emit('refresh')"
          @close="close"
        />
      </v-card>
    </v-dialog>
    <fb-pixel v-if="party.fbPixelId" :fbPixelId="party.fbPixelId" />
  </div>
</template>

<script>
import TICKET from "@/services/app/ticket";
import MercadoPagoPayment from "../../../components/app/modals/payment/MercadoPagoPayment.vue";
import PagseguroPayment from "../../../components/app/modals/payment/PagseguroPayment.vue";
import CieloPayment from "../../../components/app/modals/payment/CieloPayment.vue";
import FbPixel from "../../../components/global/FbPixel.vue";

export default {
  components: { MercadoPagoPayment, PagseguroPayment, CieloPayment, FbPixel },
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    payment: null,
    progressBar: {
      initial: 0,
      time: 0,
      interval: null,
    },
    paymentMethods: {
      MERCADO_PAGO: {
        component: "MercadoPagoPayment",
      },
      PAGSEGURO: {
        component: "PagseguroPayment",
      },
      CIELO: {
        component: "CieloPayment",
        maxWidth: "470px",
      },
    },
  }),
  methods: {
    close() {
      this.dialog = false;
      this.payment = null;
      this.error = false;
    },
    async open() {
      this.dialog = true;
      this.error = false;
      try {
        this.loading = true;
        await this.getPayment();
        this.loading = false;
        this.$root.$emit("initiate_checkout", {
          amount: this.payment.amount,
          Tickets: this.tickets,
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.error = error.message;
      }
    },

    async getPayment() {
      const { payment, tickets, data } = await TICKET.getPayment(
        this.paymentId
      );
      this.payment = payment;
      this.tickets = tickets;
      this.integrationData = data;
    },
  },
  computed: {
    bankRestriction() {
      return this.tickets.find((ticket) => ticket.TicketBlock?.BankRestriction)
        ?.TicketBlock?.BankRestriction;
    },
  },
  async mounted() {
    this.$parent.$on("pay", this.open);
  },

  props: {
    paymentId: {
      type: String,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>
